import screen1 from './img/1.webp'
import screen2 from './img/2.webp'
import screen3 from './img/3.webp'
import screen4 from './img/4.webp'
import screen5 from './img/5.webp'
import screen6 from './img/6.webp'
import screen9 from './img/9.webp'

export const HelpContent = () => {
    return (
        <div className="help_popup_content">
            <h1>Пошаговая инструкция по использованию скрипта для проверки уникальности сайтов <a href="https://webanalyzer.pro/">webanalyzer.pro</a></h1>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/ySAPnhl-nQs?si=j4obm5WqNir5CKaa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
            <p>Этот сайт позволяет сравнивать веб-страницы по их ключевым компонентам — HTML, CSS и JavaScript, — чтобы определить, насколько они схожи. В результате вы получите процентное соотношение схожести для каждого компонента и общий сводный индекс. Главная цель — оценить уникальность страниц.</p>
            <p>Скрипт сравнивает не весь сайт, а только те страницы, ссылки на которые вы добавляете.</p>
            <ol>
                <li>
                    Чтобы воспользоваться данным скриптом, необходимо получить user ID, написав письмо в телеграм <a href="https://t.me/sales_knk">@sales_knk</a> с просьбой о получении этого идентификатора в произвольной форме.<br />Для наших клиентов действует реферальная программа 25%. Для получения  реферальной ссылки пишите нам.
                </li>
                <li>
                    Переходим по ссылке <a href="https://webanalyzer.pro/">https://webanalyzer.pro/</a>.
                </li>
                <li>
                    В поле <strong>User Name</strong> указываем идентификатор (user ID), описанный в пункте 1. Нажимаем кнопку <strong>Auth</strong> (см. скрин).
                    <div className="adaptive"><img src={screen1} alt="Скриншот с кнопкой Auth" /></div>
                </li>
                <li>
                    В следующем текстовом поле вводим полный адрес сайта в формате <b>https://example.com/</b>, с которым будем сравнивать остальные сайты (см. скрин).
                    <div className="adaptive"><img src={screen2} alt="Скриншот с полем для ввода основного сайта" /></div>
                    Если вы уже выполняли проверку, используя этот скрипт, то при вводе своего user ID, описанного в пункте 1 этой инструкции, в данном текстовом поле отображается адрес сайта, который вы указали во время последней проверки. При необходимости вводим новый адрес сайта, с которым будем сравнивать остальные сайты.
                </li>
                <li>
                    В еще одном текстовом поле вводим список сайтов в формате <b>https://site1.com/</b>, которые будем сравнивать с основным сайтом, указанным в пункте 4 (см. скрин).
                    <div className="adaptive"><img src={screen3} alt="Скриншот с полем для ввода списка сайтов" /></div>
                    Если вы уже выполняли проверку, используя этот скрипт, то при вводе своего user ID, описанного в пункте 1 этой инструкции, в данном текстовом поле отображаются адреса сайтов, которые вы указали во время последней проверки. При необходимости вводим новые адреса сайтов, которые будем сравнивать с основным сайтом.
                </li>
                <li>
                    Нажимаем на кнопку <strong>Проверить</strong> (см. скрин).
                    <div className="adaptive"><img src={screen4} alt="Скриншот с кнопкой Проверить" /></div>
                    При этом количество проверок уменьшается на 1.
                </li>
                <li>
                    <h3>В поле с черным фоном вы получите результат в формате:</h3>
                    <code>
        Сводный индекс сходства между https://example.com/ и https://site1.com/ составляет 35.45%<br />
        Сходство HTML 39.23% (желтый)<br />
        Сходство CSS 30.11% (желтый)<br />
        Сходство JavaScript 38.88% (желтый)
                    </code>
                    <p>
                        <strong>Интерпретация:</strong> Сводный индекс составляет 35.45%, что ниже 40%. Все три компонента (HTML — 39.23%, CSS — 30.11%, и JavaScript — 38.88%) находятся в диапазоне от 10% до 40%, поэтому значения выводятся желтым цветом, что указывает на умеренную уникальность сайта.
                    </p>
                    <h3>Пример вывода:</h3>
                    <code>
        Сводный индекс сходства между https://example.com/ и https://best-example-site.com/ составляет 1.51%<br />
        Сходство HTML 2.85% (зеленый)<br />
        Сходство CSS 0.21% (зеленый)<br />
        Сходство JavaScript 0.16% (зеленый)
                    </code>
                    <p>
                        <strong>Интерпретация:</strong> Все показатели (HTML, CSS, JavaScript) ниже 10%. Все значения выводятся зеленым цветом, что указывает на высокую уникальность сайта.
                    </p>
                    <h3>Пример вывода:</h3>
                    <code>
        Сводный индекс сходства между https://example.com/ и https://site2.com/ составляет 65.78%<br />
        Сходство HTML 70.56% (красный)<br />
        Сходство CSS 60.12% (красный)<br />
        Сходство JavaScript 68.34% (красный)
                    </code>
                    <p>
                        <strong>Интерпретация:</strong> Сводный индекс составляет 65.78%, а все три компонента (HTML — 70.56%, CSS — 60.12%, и JavaScript — 68.34%) превышают 40%. Эти значения выводятся красным цветом, что указывает на низкую уникальность сайта и его схожесть с основным сайтом.
                    </p>
                </li>
                <li>
                    Также все ошибки, которые возникают во время выполнения проверки, подсвечиваются красным цветом с надписью <strong>error</strong> и описанием проблемы (см. скрин).
                    <div className="adaptive"><img src={screen5} alt="Скриншот с ошибкой" /></div>
                </li>
                <li>
                    Вы также можете сохранить результаты сравнения компонента HTML, CSS или JavaScript двух различных сайтов у себя на компьютере. Для этого нажимаем на иконку возле нужного вам компонента и выбираем папку на компьютере, где хотим сохранить файл. Нажимаем кнопку <strong>сохранить</strong>. В сохраненном файле разный (уникальный) код имеет красную (для первого сайта) и зеленую (для второго сайта) заливки фона соответственно. Незначительные изменения имеют желтую заливку фона. Одинаковый (похожий) код для двух сайтов не имеет заливки (см. скрин).
                    <div className="adaptive"><img src={screen6} alt="Скриншот с результатами сравнения кода" /></div>
                </li>
                <li>
                    Чтобы пополнить количество проверок или получить ответы на возникающие вопросы, можно написать письмо в телеграм <a href="https://t.me/sales_knk">@sales_knk</a> (отвечаем оперативно). Оставшееся количество проверок указано в строке <strong>Проверок осталось: (количество проверок)</strong> (см. скрин).
                    <div className="adaptive"><img src={screen9} alt="Скриншот с количеством оставшихся проверок" /></div>
                </li>
            </ol>
            <h2>Критерий уникальности</h2>
            <p>Сайт считается уникальным, если процент сходства по каждому из компонентов (HTML, CSS, JavaScript) составляет менее 40%. Если хотя бы один компонент превышает 40%, сайт считается схожим с основным.</p>
            <h2>Пример вывода:</h2>
            <p>После выполнения скрипта вы получите следующие результаты:</p>
            <p>Сводный индекс сходства между https://example.com/ и https://site1.com/: 35.45%<br />
            Сходство HTML: 39.23%<br />
            Сходство CSS: 30.11%<br />
            Сходство JavaScript: 38.88%</p>
            <p>В этом примере:</p>
            <ul>
                <li>Сводный индекс составляет 35.45%, что ниже 40%.</li>
                <li>Все три компонента (HTML — 39.23%, CSS — 30.11%, и JavaScript — 38.88%) ниже 40%, поэтому сайт уникален.</li>
            </ul>
            <h2>Пример с несоответствием:</h2>
            <p>Сводный индекс сходства между https://example.com/ и https://site2.com/: 65.78%<br />
            Сходство HTML: 70.56%<br />
            Сходство CSS: 60.12%<br />
            Сходство JavaScript: 68.34%</p>
            <p>В этом примере:</p>
            <ul>
                <li>Все показатели превышают 40%, следовательно, сайт не уникален и имеет высокую степень схожести с основным сайтом.</li>
            </ul>
            <h2>Как работает расчет сводного индекса</h2>
            <p>Для вычисления общего индекса схожести используется следующая формула:</p>
            <p>Сводный индекс = 0.5 &times; HTML схожесть + 0.25 &times; CSS схожесть + 0.25 &times; JavaScript схожесть</p>
            <ul>
                <li>HTML — основа структуры страницы, поэтому его вклад составляет 50%.</li>
                <li>CSS и JavaScript — влияют на внешний вид и функциональность, поэтому на каждый из них отводится по 25%.</li>
            </ul>
            <h2>Оценка уникальности сайта:</h2>
            <ul>
                <li>Если все три компонента (HTML, CSS, JavaScript) имеют процент сходства менее 40%, сайт считается уникальным.</li>
                <li>Если хотя бы один компонент превышает 40%, сайт не является уникальным.</li>
            </ul>
            <h2>Итог:</h2>
            <ul>
                <li>Менее 40% для всех компонентов = сайт уникален.</li>
                <li>Более 40% для одного или нескольких компонентов = сайт не уникален.</li>
            </ul>
            <h2>Как сделать сайт более уникальным:</h2>
            <p>Для того чтобы сайт был более уникальным и не повторялся с другими, можно использовать следующие методы и стратегии:</p>
            <p><b>Рекомендации: перед тем как уникализировать сайт,  обязательно попробуйте изменить хостинг и доменный регистратор, даже если используете Cloudflare. В некоторых случаях этого может быть достаточно.</b></p>
            <p><b>По возможности не используйте публичные клоаки</b></p>
            <ul>
                <li>Используйте разные хостинги и доменные регистраторы для создания уникальных комбинаций. Если у вас есть 5 доменных регистраторов и 5 хостинг провайдеров, на выходе вы получите 25 уникальных комбинаций.</li>
                <li>На каждый новый сайт добавляйте 5-7 случайных плагинов из воркшопа WordPress. Это сделает ваш WordPress уникальным с точки зрения набора плагинов. Старайтесь не использовать одинаковые плагины на всех сайтах. Для каждой задачи, например, для форм обратной связи, подберите 3-4 подходящих плагина и ротируйте их случайным образом на каждом новом сайте. Таким образом, в результате получится огромное количество уникальных комбинаций плагинов.</li>
                <li>Переименуйте классы в CSS стилях: измените имена классов, чтобы избежать дублирования с другими сайтами, которые могут использовать те же шаблоны.</li>
                <li>Поменяйте структуру и количество блоков в HTML-файле: внесение изменений в структуру страниц или их содержание поможет уменьшить схожесть.</li>
                <li>Отключите инлайновые стили: использование внешних файлов стилей вместо инлайновых улучшит уникальность и структуру кода.</li>
                <li>Избегайте использования готовых шаблонов: если вы используете CMS (например, WordPress), старайтесь максимально персонализировать шаблон.</li>
                <li>Уникализируйте изображения и графику: создавайте уникальные изображения, логотипы и иконки.</li>
                <li>Используйте серверные скрипты для динамического контента: добавление динамически изменяющегося контента (например, новостей или продуктов) сделает сайт более уникальным.</li>
                <li>Работайте с шрифтами: используйте кастомные шрифты и их комбинации.</li>
                <li>Настраивайте анимации и взаимодействия с пользователем: добавление уникальных анимаций, таких как оригинальный прелоадер, или использование анимации при прокрутке страницы, может сделать сайт более визуально привлекательным и уникальным. Примером может быть добавление анимации при загрузке контента, которая будет показываться до полной загрузки страницы.</li>
            </ul>
        </div>
    )
}