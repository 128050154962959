import { useEffect, useRef, useState } from 'react';
import { HelpContent } from './help';

import './App.css';
import { ReactComponent as Download } from './download.svg';

// eslint-disable-next-line
const getCookieByName = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

const setCookieByName = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
};

const getPythonData = async (url1, url2) => {
    const response = await fetch(`https://4python.site/?url1=${encodeURIComponent(url1)}&url2=${encodeURIComponent(url2)}`);
    const json = await response.json();
    return json;
};

const getColor = percent => {
    if (percent < 10) {
        return 'green';
    } else if (percent >= 10 && percent < 40) {
        return 'yellow';
    } else {
        return 'red';
    }
};

const App = () => {
    const domain = 'webanalyzer.pro';
    let errorsList = [];
    const [userName, setUserName] = useState('');
    const [userID, setUserID] = useState(0);
    const [userAvailable, setUserAvailable] = useState(0);
    const [resultData, setResultData] = useState([]);
    const [comparedSite, setComparedSite] = useState('');
    const [comparedList, setComparedList] = useState('');
    const [refID, setRefID] = useState('');

    const loader = useRef(null);
    const helpPopup = useRef(null);
    const resultBlockContent = useRef(null);

    const ShowLoader = () => loader.current.classList.add('active');
    const HideLoader = () => loader.current.classList.remove('active');

    const showHelpPopup = () => helpPopup.current.classList.add('active');
    const hideHelpPopup = () => helpPopup.current.classList.remove('active');

    const userNameChange = event => {
        setUserName(event.target.value);
    };

    const submitUserName = async (event) => {
        event.preventDefault();
        ShowLoader();
        try {
            const response = await fetch(`https://${domain}/api.php?action=getuserdatabyname&username=${encodeURIComponent(userName)}`);
            const json = await response.json();
            if (json.status === 200) {
                setUserID(json.id);
                setUserAvailable(json.available);
                setComparedSite(json.url);
                setComparedList(json.urllist.join('\n'));
            }
            if (json.status === 500) {
                setUserID(0);
                setUserAvailable(0);
                setComparedSite('');
                setComparedList('');
            }
        } catch (error) {
            console.error(error);
        } finally {
            HideLoader();
        }
    };

    const comparedSiteChange = async (event) => {
        const value = event.target.value.trim();
        setComparedSite(value);
        localStorage.setItem('first.site', value);
        ShowLoader();
        try {
            await fetch(`https://${domain}/api.php?action=savefield&id=${userID}&name=url&value=${encodeURIComponent(value)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });
        } catch (error) {
            console.error(error);
        } finally {
            HideLoader();
        }
    };

    const comparedListChange = async (event) => {
        const value = event.target.value;
        setComparedList(value);
        localStorage.setItem('website.list', value.trim());
        ShowLoader();
        try {
            await fetch(`https://${domain}/api.php?action=savefield&id=${userID}&name=urllist&value=${encodeURIComponent(value.split('\n').join('|'))}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });
        } catch (error) {
            console.error(error);
        } finally {
            HideLoader();
        }
    };

    const compareSites = async (event) => {
        event.preventDefault();

        const websiteCompare = comparedSite.trim();

        // Обрабатываем список сайтов для проверки
        const updatedList = comparedList.trim().split('\n').filter(Boolean).map(site => {
            const trimmedSite = site.trim();
            if (trimmedSite && !trimmedSite.startsWith('https://') && !trimmedSite.startsWith('http://')) {
                return `https://${trimmedSite}`;
            }
            return trimmedSite;
        }).join('\n');
        setComparedList(updatedList);
        const websiteList = updatedList.split('\n');
        localStorage.setItem('website.list', updatedList);

        ShowLoader();

        // Сохраняем обновлённый список через API
        try {
            await fetch(`https://${domain}/api.php?action=savefield&id=${userID}&name=urllist&value=${encodeURIComponent(updatedList.split('\n').join('|'))}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });
        } catch (error) {
            console.error(error);
        }

        setResultData([]);
        errorsList = [];

        try {
            const resp = await fetch(`https://${domain}/api.php?action=getavailablebyid&id=${userID}`);
            const json = await resp.json();
            if (parseInt(json.available) > 0) {
                // Последовательная обработка сайтов
                for (const site2 of websiteList) {
                    try {
                        const json = await getPythonData(websiteCompare, site2);
                        if (json.status === 500) errorsList.push(json.error);
                        setResultData(prevResults => [...prevResults, json]);
                    } catch (error) {
                        console.error(error);
                    }
                }

                // После всех сравнений обновляем количество доступных проверок
                try {
                    const response = await fetch(`https://${domain}/api.php?action=minusone&id=${userID}&url=${encodeURIComponent(comparedSite)}&urllist=${encodeURIComponent(comparedList.split('\n').join('|'))}&errors=${encodeURIComponent(errorsList.join('|'))}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    });
                    const data = await response.json();
                    const available = data.available;
                    setUserAvailable(available);
                    setCookieByName('available', available, 365);
                } catch (error) {
                    console.error('Error:', error);
                } finally {
                    HideLoader();
                }
            } else {
                HideLoader();
            }
        } catch (error) {
            console.error(error);
            HideLoader();
        }
    };

    const copyClick = () => {
        if (resultBlockContent.current) {
            // Получаем HTML содержимое
            const content = resultBlockContent.current.innerHTML;

            // Преобразуем HTML в текст с сохранением переносов строк
            const plainText = content
                .replace(/<br\s*\/?>/gi, '\n')
                .replace(/<\/p>/gi, '\n')
                .replace(/<[^>]+>/g, '');

            // Копируем текст в буфер обмена
            navigator.clipboard.writeText(plainText)
                .then(() => {})
                .catch((err) => {
                    console.error('Ошибка при копировании: ', err);
                });
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const rValue = searchParams.get('r');
        if (rValue) {
            setRefID(rValue);
        }
    }, []);

    return (
        <>
            <div className="App">
                <div className="app_container">
                    <div className="app_header">
                        <form className="app_header_form" onSubmit={submitUserName}>
                            <input
                                type="text"
                                placeholder="User Name"
                                className="app_header_form_input"
                                value={userName}
                                onChange={userNameChange}
                            />
                            <button type="submit" className="app_header_form_submit">Auth</button>
                        </form>
                        <div className="app_header_info">
                            {userAvailable > 0 && `Проверок осталось: ${userAvailable}`}
                            {refID.trim() !== '' && <div>RefID: {refID}</div>}
                        </div>
                        <a
                            className="app_header_tg_button"
                            href="https://t.me/sales_knk"
                            target="_blank"
                            rel="nofollow noreferrer noopener"
                        >Пополнение<span className="hide_on_1024">&nbsp;и вопросы</span><span className="hide_on_750">: @sales_knk</span></a>
                    </div>
                    <form className="compared_site_form" onSubmit={compareSites}>
                        <input
                            className="compared_site_form_input"
                            value={comparedSite}
                            onChange={comparedSiteChange}
                            placeholder="Сайт для сравнения"
                            required
                        />
                        <button
                            type="submit"
                            className="compared_site_form_submit"
                            disabled={userAvailable < 1}
                        >Проверить</button>
                        <button
                            type="button"
                            className="get_help"
                            onClick={showHelpPopup}
                        >?</button>
                    </form>
                    <textarea
                        className="compared_list"
                        value={comparedList}
                        onChange={comparedListChange}
                        placeholder="Список сайтов"
                    ></textarea>
                    <div className="result_block">
                        <div className="result_block_content" ref={resultBlockContent}>
                            {resultData.map((e, i) => (
                                e.status === 200 ? (
                                    <p key={`resultid${i}`}>
                                        Сводный индекс сходства между<br />
                                        <a href={e.url1} target="_blank" rel="nofollow noopener noreferrer">{e.url1}</a>
                                        <br />
                                        <a href={e.url2} target="_blank" rel="nofollow noopener noreferrer">{e.url2}</a>
                                        <br />
                                        Составляет <span className={getColor(e.total_similarity)}>{e.total_similarity}%</span><br />
                                        <a download href={e.diff_links?.html_diff} className="app_download"><Download /></a> Сходство HTML <span className={getColor(e.html_similarity)}>{e.html_similarity}%</span><br />
                                        <a download href={e.diff_links?.css_diff} className="app_download"><Download /></a> Сходство CSS <span className={getColor(e.css_similarity)}>{e.css_similarity}%</span><br />
                                        <a download href={e.diff_links?.js_diff} className="app_download"><Download /></a> Сходство JS <span className={getColor(e.js_similarity)}>{e.js_similarity}%</span>
                                    </p>
                                ) : (
                                    <p key={`resultid${i}`} className="app_error">Error: {e.error}</p>
                                )
                            ))}
                        </div>
                        <button className="app_copy" onClick={copyClick} type="button">&#128203;</button>
                    </div>
                </div>
                <div className="loader" ref={loader}></div>
                <div className="help_popup" ref={helpPopup}>
                    <HelpContent />
                    <button
                        className="help_popup_content_close"
                        onClick={hideHelpPopup}
                    ></button>
                    <div
                        className="help_popup_overlay"
                        onClick={hideHelpPopup}
                    ></div>
                </div>
            </div>
        </>
    );
};

export default App;
